import axios from 'axios';
import { APIkeyV2 } from 'src/api/dataServices';

const apikey = process.env.REACT_APP_HOSTNAME;
const apikeyV2 = process.env.REACT_APP_HOSTNAME_v2;
export const getAllCustomerTypes = async () => {
  try {
    // console.log(`${apikey}`)
    const {data} = await axios.get(`${apikey}/fields/getallcustomertypes/`);
    // console.log(`${process.env.REACT_APP_HOST}`)
    //  console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getAllLocationTypes = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/locationtype/`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const initializeBankStatementAnalyzer = async (
  redirectUrl,
  link,
  numberOfMonthsToFetch,
  bankName,
  entityType
) => {
  try {
    const {data} = await axios.post(
      `${apikeyV2}/documents/validation/bank-statement/initiate`,
      {
        entityId: link,
        numberOfMonthsToFetch: numberOfMonthsToFetch,
        redirectUrl: redirectUrl,
        bankName: bankName,
        entityType:entityType,
      }
    );
    // console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getAllStates = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getallstates/`);
    //console.log(data)
    //console.log(`${process.env.REACT_APP_HOST}`)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getFilteredCities = async id => {
  try {
    // console.log(id)
    //console.log(`${apikey}/fields/getfilteredcities/${id}`)
    const {data} = await axios.get(`${apikey}/fields/getfilteredcities/${id}`);
    // console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getareaServedCities = async id => {
  try {
    // console.log(id)
    //console.log(`${apikey}/fields/getfilteredcities/${id}`)
    const {data} = await axios.get(
      `${apikey}/fields/getareaServedcities/${id}`
    );
    // console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const saveDistirbutorLinkState = async (link, inputData, tabName) => {
  try {
    const {data} = await axios.post(`${apikeyV2}/invitation-links/steps`, {
      link: link,
      tabName: tabName,
      data: inputData,
    });
    // console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const saveUserConsent = async (link, flowName, consentFor, entityType) => {
  try {
    const {data} = await axios.post(`${apikeyV2}/consent`, {
      entityId: link,
      consentFor: consentFor,
      flowName: flowName,
      entityType: entityType
    });
    // console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const validateBusinessPanNumber = async panNumber => {
  try {
    const {data} = await axios.post(
      `${apikeyV2}/kyc/documents/businessPan/verify`,
      {
        panNumber,
      }
    );
    // console.log(data)
    return data;
  } catch (err) {
    return err.response;
  }
};

export const fetchGSTCertificate = async (userName, password, link) => {
  try {
    const {data} = await axios.post(`${apikeyV2}/documents/download/gst`, {
      userName: userName,
      password: password,
      uploadToS3: true,
      entityId: link,
      filePath: 'distributor/' + link + '/gst_certificate.pdf',
    });
    // console.log(data)
    return data;
  } catch (err) {
    return err.response;
  }
};

export const fetchITRForm = async (userName, password, link) => {
  try {
    const {data} = await axios.post(
      `${apikeyV2}/documents/download/itr-form-initiate`,
      {
        userName: userName,
        password: password,
        uploadToS3: true,
        entityId: link,
        filePath: 'distributor/' + link + '/itr_form.pdf',
      }
    );
    // console.log(data)
    return data;
  } catch (err) {
    return err.response;
  }
};

export const checkifDistributorExists = async (pan, email) => {
  try {
    const {data} = await axios.post(`${apikey}/distributor/exists`, {
      pan: pan,
      email: email,
    });
    console.log(data);
    return data;
  } catch (err) {
    return err.response;
  }
};

export const fetchGSTReport = async (userName, password, link, gstIn) => {
  try {
    const {data} = await axios.post(
      `${apikeyV2}/documents/gst/report/initiate-request`,
      {
        username: userName,
        password: password,
        gstin: gstIn,
        uploadToS3: true,
        entityId: link,
        filePath: 'distributor/' + link + '/gst_report.pdf',
      }
    );
    // console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllDocumentType = async () => {
  try {
    //console.log(`${apikey}/fields/getalldocuments/`)
    const {data} = await axios.get(`${apikey}/fields/getalldocuments/`);
    //console.log(`${process.env.REACT_APP_HOST}`)
    //console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getDSADocumentType = async () => {
  try {
    //console.log(`${apikey}/fields/getalldocuments/`)
    const {data} = await axios.get(`${apikey}/fields/getdsadocuments/`);
    //console.log(`${process.env.REACT_APP_HOST}`)
    //console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getEPCDocumentType = async () => {
  try {
    //console.log(`${apikey}/fields/getalldocuments/`)
    const {data} = await axios.get(`${apikey}/fields/getepcdocuments/`);
    //console.log(`${process.env.REACT_APP_HOST}`)
    //console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getEpcRegistrationType = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getEpcRegistrationType/`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getEpcRegistrationTypeNew = async () => {
  try {
    const {data} = await axios.get(
      `${apikey}/fields/getEpcRegistrationTypeNew/`
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllDsaActivities = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getActivities`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllDsaExperiences = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getExperiences`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const insertCustomer = async formdata => {
  try {
    const {data} = await axios.post(`${apikey}/customer/`, formdata, {
      withCredentials: 'true',
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const insertepc = async formData => {
  // console.log(formData);
  try {
    const {data} = await axios.post(`${apikey}/epc/`, formData, {
      withCredentials: 'true',
    });
    // console.log(data);
    //  if(data.message=="EPC Registered Successfully"){
    //   action.resetForm();
    //  }
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const insertDistributor = async formData => {
  // console.log(formData);
  try {
    const {data} = await axios.post(`${apikey}/distributor/join`, formData, {
      withCredentials: 'true',
    });
    // console.log(data);
    //  if(data.message=="EPC Registered Successfully"){
    //   action.resetForm();
    //  }
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Dsa
export const insertDSA = async formData => {
  try {
    // console.log(formData);

    const {data} = await axios.post(`${apikey}/dsa/`, formData, {
      withCredentials: 'true',
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const insertCustomerLead = async formdata => {
  try {
    const {data} = await axios.post(`${apikey}/customer/NewLead/`, formdata, {
      withCredentials: 'true',
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const insertLeads = async formdata => {
  //console.log(formdata);
  try {
    //console.log(data);
    const {data} = await axios.post(`${apikey}/leads/`, formdata, {
      withCredentials: 'true',
    });
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const locationType = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/locationtype/`);

    //console.log(`${process.env.REACT_APP_HOST}`)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// const token= Cookies.get('token');

export const forgotPassword = async formdata => {
  try {
    console.log('formdata');
    console.log(formdata);
    const {data} = await axios.post(
      `${apikey}/user/forgot-password`,
      formdata,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const resetPassword = async formdata => {
  try {
    console.log('formdata');
    console.log(formdata);
    const {data} = await axios.put(
      `${apikey}/user/reset-password/${formdata.token}`,
      formdata,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllUserTypes = async (req, res) => {
  try {
    const {data} = await axios.get(`${apikey}/fields/get-all-user-types`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const auth = async () => {
  try {
    const data = await axios.get(`${apikey}/user/authorized`, {
      withCredentials: true,
    });

    // console.log(data)
    return data;
  } catch (err) {
    // console.log(err.request.status)
    return err.request.status;
  }
};

export const getEpcDocument2 = async id => {
  try {
    // console.log(id)
    // console.log("in getEpcDocument2")
    const {data} = await axios.get(`${apikey}/fields/getepcdocument2/${id}`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getallratematrixcomponents = async () => {
  try {
    const {data} = await axios.get(
      `${apikey}/fields/getallratematrixcomponents/`
    );

    // console.log("getallratematrixcomponents")
    // console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getallratewattagecomponents = async () => {
  try {
    const {data} = await axios.get(
      `${apikey}/fields/getallratewattagecomponents/`
    );
    // console.log("getallratewattagecomponents")
    // console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//send OTP
export const sendOtpRequest = async sendOtpData => {
  try {
    const {data} = await axios.post(`${apikey}/user/send-otp`, sendOtpData, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//send OTP
export const sendPartnersOtpRequest = async sendOtpData => {
  try {
    const {data} = await axios.post(
      `${apikey}/user/send-partners-otp`,
      sendOtpData,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
//send OTP
export const sendDsaOtpRequest = async sendOtpData => {
  try {
    const {data} = await axios.post(
      `${apikey}/user/send-dsa-otp`,
      sendOtpData,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//verify Email OTP
export const verifyEmailOTP = async emailOTP => {
  try {
    const {data} = await axios.post(
      `${apikey}/user/verify-email-otp`,
      {emailOTP},
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//verify Email OTP
export const verifySmsOTP = async smsOTP => {
  try {
    const {data} = await axios.post(
      `${apikey}/user/verify-sms-otp`,
      {smsOTP},
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getEpcPublishingDetails = async () => {
  try {
    const {data} = await axios.get(`${apikey}/epc/get-epc-publishing-details`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getPPPublishingDetails = async () => {
  try {
    const {data} = await axios.get(`${apikey}/epc/get-pp-publishing-details`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getDsaPublishingDetails = async () => {
  try {
    const {data} = await axios.get(`${apikey}/dsa/get-dsa-publish-details`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//verify Email OTP
export const verifyEpcEmail = async (email, newErrors) => {
  try {
    const {data} = await axios.post(
      `${apikey}/epc/checkExistingEpc`,
      {email},
      {
        withCredentials: true,
      }
    );
    if (data.message == 'EPC Already Exists') {
      newErrors.email = 'EPC already exists. Please Login.';
      // toast.error("EPC already exists. Please Login.");
    } else {
      newErrors.email = '';
    }
    return data;
  } catch (err) {
    // if(err.response.data.message=="EPC Already Exists"){
    //   newErrors.email = 'EPC already exists. Please Login.';
    // }else{
    //   newErrors.email ="";
    // }
    throw Error(err.response.data.message);
  }
};

export const getFinanceDocumentRequiredForLocationType = async locationType => {
  try {
    if (!locationType) {
      return;
    }
    const {data} = await axios.get(
      `${apikey}/finance/documents-required/${locationType}`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getDistributorInviteLinkDetails = async link => {
  try {
    const {data} = await axios.get(`${apikeyV2}/epc/links/${link}`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getRequiredDocsForKyc = async (
  customerTypeId,
  expectedLimitInLac,
  gstManualFetch,
  itrManualFetch
) => {
  let apiUrl = `${apikeyV2}/epc/kyc/${customerTypeId}/required-docs?expectedLimitInLacs=${expectedLimitInLac}`;
  if (gstManualFetch) {
    apiUrl = apiUrl + '&gstAlreadyFetched=false';
  }
  if (itrManualFetch) {
    apiUrl = apiUrl + '&itrAlreadyFetched=false';
  }
  try {
    const {data} = await axios.get(apiUrl, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const customerApplication = async ({ applicationCode, body }) => {
  try {
    const { data } = await axios.post(
      `${APIkeyV2}/customer/applications/kyc/tabs/${applicationCode}`,
      body,
      {
        withCredentials: 'true'
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const uploadCustomerApplicationDocuments = async ({ applicationCode, body }) => {
  try {
    const { data } = await axios.post(
      `${APIkeyV2}/customer/applications/kyc/tabs/${applicationCode}/documents`,
      body,
      {
        withCredentials: true
      }
    );
    return data;
  } catch (err) {
    throw Error(err?.response?.data?.message);
  }
};

export const getLeadsDetails = async (leadCode) => {
  try {
    const { data } = await axios.get(
      `${APIkeyV2}/leads/${leadCode}/details`,
      {
        withCredentials: true
      }
    );
    return data;
  } catch (err) {
    throw Error(err?.response?.data?.message);
  }
}