const sitemapRoutes = [
  // Main Routes
  {
    path: '/',
    title: 'Dashboard',
    priority: '1.0',
    changeFreq: 'daily',
    section: 'Main'
  },
  {
    path: '/Aboutus',
    title: 'About Us',
    priority: '1.0',
    changeFreq: 'monthly',
    section: 'About'
  },
  {
    path: '/login',
    title: 'Login',
    priority: '0.8',
    changeFreq: 'monthly',
    section: 'Authentication'
  },
  {
    path: '/register',
    title: 'Sign Up',
    priority: '0.8',
    changeFreq: 'monthly',
    section: 'Authentication'
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    priority: '0.5',
    changeFreq: 'monthly',
    section: 'Authentication'
  },
  {
    path: '/finance',
    title: 'Finance',
    priority: '0.9',
    changeFreq: 'weekly',
    section: 'Services'
  },
  {
    path: '/PremiumPartners',
    title: 'Premium Partners',
    priority: '0.8',
    changeFreq: 'weekly',
    section: 'Partners'
  },
  {
    path: '/solar-calculator',
    title: 'Solar Calculator',
    priority: '0.9',
    changeFreq: 'monthly',
    section: 'Tools'
  },
  {
    path: '/my-solar-saving',
    title: 'My Savings',
    priority: '0.8',
    changeFreq: 'daily',
    section: 'User'
  },
  {
    path: '/loan-products/residential',
    title: 'Residential Loan',
    priority: '0.9',
    changeFreq: 'weekly',
    section: 'Loans'
  },
  {
    path: '/knowledge',
    title: 'Knowledge Base',
    priority: '0.8',
    changeFreq: 'weekly',
    section: 'Resources'
  },
  {
    path: '/lease',
    title: 'Lease',
    priority: '0.8',
    changeFreq: 'monthly',
    section: 'Loans'
  },
  {
    path: '/coming-soon',
    title: 'Coming Soon',
    priority: '0.8',
    changeFreq: 'monthly',
    section: 'Promo'
  },
  {
    path: '/mission',
    title: 'Mission',
    priority: '0.8',
    changeFreq: 'montly',
    section: 'Mission'
  },
  {
    path: '/contact-sales',
    title: 'Contact Us',
    priority: '0.8',
    changeFreq: 'montly',
    section: 'Contact'
  },
  {
    path: '/lease-contact-sales',
    title: 'Loan Contact Us',
    priority: '0.8',
    changeFreq: 'montly',
    section: 'Contact'
  },

  // Policy Pages
  {
    path: '/CookiePolicy',
    title: 'Cookie Policy',
    priority: '0.6',
    changeFreq: 'monthly',
    section: 'Policies'
  },
  {
    path: '/Privacypolicy',
    title: 'Solfin Privacy Policy',
    priority: '0.6',
    changeFreq: 'monthly',
    section: 'Policies'
  },
  {
    path: '/DhanvikasPrivacypolicy',
    title: 'Dhanvikas Privacy Policy',
    priority: '0.6',
    changeFreq: 'monthly',
    section: 'Policies'
  },
  {
    path: '/policies/refund-policy',
    title: 'Refund Policy',
    priority: '0.6',
    changeFreq: 'monthly',
    section: 'Policies'
  },
  {
    path: '/FairPracticeCode',
    title: 'Fair Practice Code',
    priority: '0.6',
    changeFreq: 'monthly',
    section: 'Policies'
  },
  {
    path: '/GrievanceRedressalPolicy',
    title: 'Grievance Redressal Policy',
    priority: '0.6',
    changeFreq: 'monthly',
    section: 'Policies'
  },
  {
    path: '/Termsandconditions',
    title: 'Terms and Conditions',
    priority: '0.6',
    changeFreq: 'monthly',
    section: 'Policies'
  },

  // Partner Pages
  {
    path: '/epc',
    title: 'EPC',
    priority: '0.8',
    changeFreq: 'weekly',
    section: 'Partners'
  },
  {
    path: '/dsa',
    title: 'DSA Onboarding',
    priority: '0.8',
    changeFreq: 'weekly',
    section: 'Partners'
  },
  {
    path: '/distributor/join',
    title: 'Join as Distributor',
    priority: '0.8',
    changeFreq: 'weekly',
    section: 'Partners'
  }
];

module.exports = { sitemapRoutes };
