import { string } from 'yup';
import gonda10KWPlantImage from 'src/assets/images/projectsInstalled/gonda-10kw-project-image.jpg';
import kushinagar17KWPlantImage from 'src/assets/images/projectsInstalled/kushinager-17kw-project-image.png';
import ceratecAntara70KWPlantImage from 'src/assets/images/projectsInstalled/CeratecAntara-70KW-Yewalewadi-Pune-project-image.jpg';
import bhutDilip4_36KWJamnagarImage from 'src/assets/images/projectsInstalled/bhut_dilip-4.36KW-Jamnagar,Gujarat.png';
import satyamOLeander10KWProjectImage from 'src/assets/images/projectsInstalled/satyam_O_leander-10KW-project-image.jpg';
import prajapatiPradip4_6KWProjectImage from 'src/assets/images/projectsInstalled/prajapatiPradip-4.6KW-Janta-nagar-project-image.png';
import GuptaVishnukumar4_6KWProjectImage from 'src/assets/images/projectsInstalled/GuptaVishnukumar-4.6KW-ahmedabad-project-image.png';
import ParekhSangitaben3_3KWProjectImage from 'src/assets/images/projectsInstalled/ParekhSangitaben-3.3KW-Halol_Panchmahal-Project-Image.jpg';
import SatyachandranVekatraman3KWPojectImage from 'src/assets/images/projectsInstalled/SatyachandranVekatraman-3KW-Vellore-project-image.jpg';

export const socialLinks = {
  linkedin: 'https://in.linkedin.com/company/solfin-sustainable-financing',
  twitter: 'https://twitter.com/solfin_india',
  facebook:
    'https://www.facebook.com/profile.php?id=61558828528270&mibextid=ZbWKwL'
};

export const epcRegistrationTypes = [
  { id: 1, name: 'Sole Prop', label: 'Sole Prop' },
  { id: 2, name: 'Other', label: 'Other' }
];

export const documentsCategory = {
  COMPANY_DOCUMENT: 'Company Document',
  SIGNATORY_DOCUMENT: 'Signatory Document'
};

export const distributorTabs = [
  { id: 1, label: 'New Tasks' },
  { id: 2, label: 'In Progress Tasks' }
];

export const epcReferredLeadsTabs = [
  { id: 1, label: 'Normal Leads' },
  { id: 2, label: 'Excel Leads' }
];

export const ourProjectList = [
  {
    id: 1,
    location: 'Gonda, Uttar Pradesh',
    units: '10KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: gonda10KWPlantImage
  },
  {
    id: 2,
    location: 'kushinagar - Sukrauli, Uttar Pradesh',
    units: '17KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: kushinagar17KWPlantImage
  },
  {
    id: 3,
    location: 'Ceratec Antara, Pune, Maharashtra',
    units: '70KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: ceratecAntara70KWPlantImage
  },
  {
    id: 4,
    location: 'Jamnagar, Gujarat	',
    units: '4.36KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: bhutDilip4_36KWJamnagarImage
  },
  {
    id: 5,
    location: 'Ambernath West, Thane, Maharashtra',
    units: '10 KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: satyamOLeander10KWProjectImage
  },
  {
    id: 6,
    location: 'Janta Nagar, Ahmedabad, Gujarat',
    units: '4.6KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: prajapatiPradip4_6KWProjectImage
  },
  {
    id: 7,
    location: 'Halol, Panchmahal, Gujarat',
    units: '3.3KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: ParekhSangitaben3_3KWProjectImage
  },
  {
    id: 8,
    location: 'Ahmedabad, Gujarat',
    units: '4.6KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: GuptaVishnukumar4_6KWProjectImage
  },
  {
    id: 9,
    location: 'Vellore, Tamil Naidu',
    units: '3KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: SatyachandranVekatraman3KWPojectImage
  }
];

export const howWeWorkSteps = [
  {
    title: 'System Design',
    text: 'EPC would conduct a site visit and design a system',
    icon: '/images/solar-panel.svg',
    alt: 'System Design'
  },
  {
    title: 'Consultation',
    text: 'Discuss with EPC, the project in detail on Kwh requirement, Net Metering, Storage etc.',
    icon: '/images/consultation.svg',
    alt: 'Consultation'
  },
  {
    title: 'Flexible Financing',
    text: 'Choose best finance options basis interest rate, tenure, via state of art Digital loan processing system',
    icon: '/images/payment-plan.svg',
    alt: 'Payment Plan'
  },
  {
    title: 'Installation',
    text: 'Customer can get the project installed after closure of EPC and Financing contracts.',
    icon: '/images/installation.svg',
    alt: 'Installation'
  },
  {
    title: 'Monitoring & Maintenance',
    text: 'Low cost maintenance through EPC and special maintenance partners',
    icon: '/images/monitoring-maintance.svg',
    alt: 'Monitoring & Maintenance'
  },
  {
    title: 'Plant Optimization & Expansion',
    text: 'Storage Options and utilization options',
    icon: '/images/battery-optimization.svg',
    alt: 'Battery Optimization'
  }
];

export const loanFinancingType = {
  RESIDENTIAL: 'Residential Lead',
  COMMERCIAL_AND_INDUSTRIAL: 'C & I Lead',
  PLL: 'Performance Linked Loan',
  OTHER: 'Housing Societies / Other'
};

export const loanFinancingCategories = [
  {
    id: 'RESIDENTIAL',
    imageUrl:
      'https://png.pngtree.com/background/20231030/original/pngtree-contract-form-and-approved-home-loan-stunning-3d-illustration-picture-image_5808578.jpg',
    imageTitle: 'Residential Lead',
    isActive: true,
    title: 'Residential Lead'
  },
  {
    id: 'COMMERCIAL_AND_INDUSTRIAL',
    imageUrl:
      'https://img.freepik.com/premium-photo/3d-flat-icon-business-financial-concept-bankers-discussing-finance-services-loan-option_980716-68802.jpg',
    imageTitle: 'C & I',
    isActive: true,
    title: 'C & I'
  },
  {
    id: 'PLL',
    imageUrl:
      'https://png.pngtree.com/background/20231030/original/pngtree-contract-form-and-approved-home-loan-stunning-3d-illustration-picture-image_5808578.jpg',
    imageTitle: 'Performance Linked Loan',
    isActive: false,
    title: 'Performance Linked Loan'
  },
  {
    id: 'OTHER',
    imageUrl:
      'https://static.vecteezy.com/system/resources/previews/021/596/685/non_2x/3d-illustration-loan-in-credit-and-loan-set-free-png.png',
    imageTitle: 'Other',
    isActive: true,
    title: 'Housing Societies / Other'
  }
];

export const solfinWhatsSupportNumber = '+919599446877';
export const groupLink = 'https://chat.whatsapp.com/IYfGlyGK3qg4o3cATEUjCT';

export const whatsappSupportDefaultMessage =
  'Hello, \nI need your assistance regarding the EPC.';
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 0;

export const masterPermissionsSet = [
  'LEADS_UPDATE',
  'LEADS_CREATE',
  'LEADS_VIEW',
  'LEADS_DELETE',
  'APPLICATION_UPDATE',
  'APPLICATION_DELETE',
  'APPLICATION_VIEW',
  'CUSTOMERS_VIEW',
  'CUSTOMERS_DELETE',
  'CUSTOMERS_UPDATE',
  'CUSTOMERS_CREATE',
  'EPC_VIEW',
  'EPC_UPDATE',
  'EPC_CREATE',
  'EPC_DELETE',
  'DISTRIBUTOR_VIEW',
  'DISTRIBUTOR_CREATE',
  'DISTRIBUTOR_DELETE',
  'DISTRIBUTOR_UPDATE',
  'INVOICES_VIEW',
  'INVOICES_CREATE',
  'INVOICES_DELETE',
  'INVOICES_UPDATE',
  'DSA_VIEW',
  'DSA_CREATE',
  'DSA_DELETE',
  'DSA_UPDATE',
  'PROJECTS_VIEW',
  'PROJECTS_DELETE',
  'PROJECTS_CREATE',
  'PROJECTS_UPDATE',
  'UPDATE_KYC',
  'LOAN_VIEW',
  'LOAN_CREATE',
  'LOAN_DELETE',
  'LOAN_UPDATE'
];

export const adminPermissions = {
  LEADS_UPDATE: true,
  LEADS_CREATE: true,
  APPLICATION_VIEW: true,
  CUSTOMERS_UPDATE: true,
  PROJECTS_CREATE: true,
  INVOICES_VIEW: true,
  EPC_VIEW: true,
  DSA_CREATE: true,
  UPDATE_KYC: true
};

export const INITIAL_PAGE_NUMBER = 0;
export const INITIAL_PAGE_SIZE = 10;

export const indianStateColors = {
  AndhraPradesh: 'linear-gradient(120deg, #FF7E5F, #FEB47B)',
  ArunachalPradesh: 'linear-gradient(120deg, #4CA1AF, #C4E0E5)',
  Assam: 'linear-gradient(120deg, #FF9966, #FF5E62)',
  Bihar: 'linear-gradient(120deg, #56CCF2, #2F80ED)',
  Chhattisgarh: 'linear-gradient(120deg, #FF9A8B, #FF6A88)',
  Goa: 'linear-gradient(120deg, #36D1DC, #5B86E5)',
  Gujarat: 'linear-gradient(120deg, #8E2DE2, #4A00E0)',
  Haryana: 'linear-gradient(120deg, #43CEA2, #185A9D)',
  HimachalPradesh: 'linear-gradient(120deg, #FFB75E, #ED8F03)',
  Jharkhand: 'linear-gradient(120deg, #00C6FF, #0072FF)',
  Karnataka: 'linear-gradient(120deg, #DCE35B, #45B649)',
  Kerala: 'linear-gradient(120deg, #FF5F6D, #FFC371)',
  MadhyaPradesh: 'linear-gradient(120deg, #12C2E9, #C471ED, #F64F59)',
  Maharashtra: 'linear-gradient(120deg, #FF416C, #FF4B2B)',
  Manipur: 'linear-gradient(120deg, #1FA2FF, #12D8FA, #A6FFCB)',
  Meghalaya: 'linear-gradient(120deg, #00B4DB, #0083B0)',
  Mizoram: 'linear-gradient(120deg, #43C6AC, #191654)',
  Nagaland: 'linear-gradient(120deg, #7F00FF, #E100FF)',
  Odisha: 'linear-gradient(120deg, #D9A7C7, #FFFCDC)',
  Punjab: 'linear-gradient(120deg, #F7971E, #FFD200)',
  Rajasthan: 'linear-gradient(120deg, #FDFC47, #24FE41)',
  Sikkim: 'linear-gradient(120deg, #F4D03F, #16A085)',
  TamilNadu: 'linear-gradient(120deg, #1D976C, #93F9B9)',
  Telangana: 'linear-gradient(120deg, #FF512F, #DD2476)',
  Tripura: 'linear-gradient(120deg, #FF758C, #FF7EB3)',
  UttarPradesh: 'linear-gradient(120deg, #F3E7E9, #E3EEFF)',
  Uttarakhand: 'linear-gradient(120deg, #52ACFF, #FFE32C)',
  WestBengal: 'linear-gradient(120deg, #FF9A8B, #FF6A88, #FF99AC)',

  // Union Territories
  AndamanAndNicobarIslands: 'linear-gradient(120deg, #FBAB7E, #F7CE68)',
  Chandigarh: 'linear-gradient(120deg, #4AC29A, #BDFFF3)',
  DadraAndNagarHaveliAndDamanAndDiu:
    'linear-gradient(120deg, #FA709A, #FEE140)',
  Delhi: 'linear-gradient(120deg, #30E8BF, #FF8235)',
  JammuAndKashmir: 'linear-gradient(120deg, #74EBD5, #9FACE6)',
  Ladakh: 'linear-gradient(120deg, #FFDEE9, #B5FFFC)',
  Lakshadweep: 'linear-gradient(120deg, #43E97B, #38F9D7)',
  Puducherry: 'linear-gradient(120deg, #36D1DC, #5B86E5)'
};

export const paymentStatusOptions = [
  {
    enumName: 'ON_TIME',
    status: 'On time'
  },
  {
    enumName: 'NPA',
    status: 'Delayed'
  },
  {
    enumName: 'OVER_DUE',
    status: 'Over due'
  }
];

export const LOAN_DOCUMENT_TYPES = {
  WELCOME_LETTER: {
    key: 'WELCOME_LETTER',
    label: 'Welcome Letter',
    isMonthly: false,
    required: true
  },
  SANCTION_LETTER_SIGNED: {
    key: 'SANCTION_LETTER_SIGNED',
    label: 'Sanction Letter (Signed)',
    isMonthly: false,
    required: true
  },
  LOAN_AGREEMENT_SIGNED: {
    key: 'LOAN_AGREEMENT_SIGNED',
    label: 'Loan Agreement (Signed)',
    isMonthly: false,
    required: true
  },
  LOAN_NOC: {
    key: 'LOAN_NOC',
    label: 'Loan NOC',
    isMonthly: false,
    required: false
  },
  MONTHLY_STATEMENT: {
    key: 'MONTHLY_STATEMENT',
    label: 'Monthly Statement',
    isMonthly: true,
    required: false
  }
};

export const DETAILED_FIELD_CONFIG = {
  // Section A: Borrower Information
  sectionA: {
    title: 'Section A: Borrower Information',
    details:
      'Please provide details of the borrower, including business and registration information.',
    fields: {
      date: {
        label: 'Date',
        type: 'date',
        defaultValue: new Date().toISOString().split('T')[0],
        validation: { required: true }
      },
      borrowerName: {
        label: 'Name of Borrower',
        type: 'text',
        validation: { required: true }
      },
      borrowerType: {
        label: 'Constitution of Borrower',
        type: 'select',
        options: ['Individual', 'Partnership', 'Company', 'Society'],
        validation: { required: true }
      },
      businessType: {
        label: 'Nature of Business',
        type: 'text',
        validation: { required: true }
      },
      officeAddress: {
        label: 'Registered Office Address',
        type: 'textarea',
        validation: { required: true }
      },
      dateOfIncorporation: {
        label: 'Date of Incorporation',
        type: 'date',
        validation: { required: true }
      }
    }
  },

  // Section B: Registration Details
  sectionB: {
    title: 'Section B: Registration Details',
    details:
      'Provide the registration and identification details for the borrower.',
    fields: {
      cin: {
        label: 'CIN/Registration Certificate',
        type: 'text'
      },
      pan: {
        label: 'PAN',
        type: 'text',
        validation: { required: true }
      },
      gst: {
        label: 'GST',
        type: 'text'
      },
      aadhaar: {
        label: 'Aadhaar',
        type: 'text'
      },
      udyam: {
        label: 'Udyam Card',
        type: 'text'
      },
      email: {
        label: 'Email',
        type: 'text',
        validation: { email: true, required: true }
      },
      phone: {
        label: 'Phone No',
        type: 'text',
        validation: { phone: true, required: true }
      },
      voterId: {
        label: 'Voter ID',
        type: 'text'
      },
      passportNo: {
        label: 'Passport No',
        type: 'text'
      }
    }
  },

  // Section C: Authorized Signatory Details
  sectionC: {
    title: 'Section C: Authorized Signatory Details',
    details:
      'Provide details of the authorized signatories, including contact and identification information.',
    canAddedMode: true,
    maxCount: 2,
    fields: {
      signatoryName: {
        label: 'Name of Authorized Signatory',
        type: 'text',
        validation: { required: true }
      },
      signatoryPan: {
        label: 'PAN of Authorized Signatory',
        validation: { required: true },
        type: 'text'
      },
      signatoryAadhaar: {
        label: 'Aadhaar of Authorized Signatory',
        validation: { required: false },
        type: 'number'
      },
      signatoryAddress: {
        label: 'Address of Authorized Signatory',
        validation: { required: true },
        type: 'textarea'
      },
      signatoryEmail: {
        label: 'Email of Authorized Signatory',
        type: 'text',
        validation: { email: true, required: true }
      },
      signatoryPhone: {
        label: 'Phone No of Authorized Signatory',
        type: 'text',
        validation: { phone: true, required: true }
      },
      signatoryName1: {
        label: 'Name of Authorized Signatory (secondary)',
        type: 'text'
      },
      signatoryPan1: {
        label: 'PAN of Authorized Signatory (secondary)',
        type: 'text'
      },
      signatoryAadhaar1: {
        label: 'Aadhaar of Authorized Signatory (secondary)',
        type: 'number'
      },
      signatoryAddress1: {
        label: 'Address of Authorized Signatory (secondary)',
        type: 'textarea'
      },
      signatoryEmail1: {
        label: 'Email of Authorized Signatory (secondary)',
        type: 'text'
      },
      signatoryPhone1: {
        label: 'Phone No of Authorized Signatory (secondary)',
        type: 'text'
      }
    }
  },

  // Section D: Loan Requirement Details
  sectionD: {
    title: 'Section D: Loan Requirements',
    details: 'Provide details for the loan requirements.',
    fields: {
      loanType: {
        label: 'Loan Type',
        type: 'select',
        options: ['Channel Finance', 'Solar Roof Top'],
        validation: { required: true }
      },
      // Channel Finance
      loanRequiredAmountCapitalFinance: {
        label: 'Loan Requirement Amount',
        type: 'number',
        validation: { required: true }
      },
      loanTenureMonthsCapitalFinance: {
        label: 'Loan Tenure (Months)',
        type: 'number'
      },
      detailedReasonCapitalFinance: {
        label: 'Purpose of Loan',
        type: 'text'
      },
      supplier: {
        label: 'Supplier',
        type: 'text'
      },
      bankAccountOfSupplier: {
        label: 'Bank Account of Supplier',
        type: 'text'
      },
      phoneNumberOfSupplier: {
        label: 'Phone No of Supplier',
        type: 'text'
      },
      emailOfSupplier: {
        label: 'Email of Supplier',
        type: 'text'
      },
      // Solar Roof Top Loan
      solarRoofTopLoanAddressOfInstallation: {
        label: 'Solar Roof Top Loan - Address of Installation',
        type: 'text'
      },
      solarRoofTopLoanInstallationPremises: {
        label: 'Installation Premises Type',
        type: 'select',
        options: ['Owned', 'Rented', 'Leased']
      },
      solarRoofTopLoanOwnershipOfPremises: {
        label: 'Solar Roof Top Loan - Ownership of Premises',
        type: 'text'
      },
      nameOfMeterOwner: {
        label: 'Name of Meter Owner',
        type: 'text'
      },
      netMeteringApprovalFromDiscom: {
        label: 'Net Metering Approval from DISCOM',
        type: 'radio',
        options: ['Yes', 'No'],
        validation: { required: true }
      },
      proformaInvoiceNo: {
        label: 'Proforma Invoice No',
        type: 'text'
      },
      proformaInvoiceAmountPanels: {
        label: 'Proforma Invoice Amount',
        type: 'number'
      }
    }
  },

  // Section E: Document Details
  sectionE: {
    title: 'Section E: Document Details',
    details:
      'Upload or provide information about the required documents and any other information.',
    fields: {
      incomeTaxReturnsForTheLast2Years: {
        label: 'Income Tax Returns for the Last 2 Years',
        type: 'text'
      },
      moaAoaPartnershipDeedLicenceForProprietorshipConcern: {
        label: 'MOA/AOA/Partnership Deed/License (for Proprietorship Concern)',
        type: 'radio',
        options: ['Yes', 'No']
      },
      financialStatementsForTheLast2Years: {
        label: 'Financial Statements for the Last 2 Years',
        type: 'radio',
        options: ['Yes', 'No']
      },
      bankStatementForTheLast1Year: {
        label: 'Bank Statement for the Last 1 Year',
        type: 'radio',
        options: ['Yes', 'No']
      },
      detailsOfAnyCourtCasesOrClaimsPendingAgainstTheBorrowerImpactingFinancialSoundnessOfTheBorrower:
        {
          label:
            'Details of any Court Cases or Claims Pending Against the Borrower Impacting Financial Soundness of the Borrower',
          type: 'text'
        },
      listOfShareholdersPartnersMembers: {
        label: 'List of Shareholders/Partners/Members',
        type: 'text' // need to change: select from list of options
      },
      applicationPlace: {
        label: 'Place of Application',
        type: 'text'
      }
    }
  },
  // Section F : Guarantor/Co-borrower
  sectionF: {
    title: 'Section F : Guarantor/co-borrower',
    details: 'Provide information of Guarantor/co-borrower.',
    fields: {
      nameOfGuarantorCoborrower: {
        label: 'Name of Guarantor/co-borrower',
        type: 'text'
      },
      fathersNameOfGuarantorCoborrower: {
        label: 'Fathers Name of Guarantor/co-borrower',
        type: 'text'
      },
      addressOfGuarantorCoborrower: {
        label: 'Address of Guarantor/co-borrower',
        type: 'textarea'
      },
      panNumberOfGuarantorCoborrower: {
        label: 'PAN Number of Guarantor/co-borrower',
        type: 'text'
      },
      aadhaarOfGuarantorCoborrower: {
        label: 'Aadhaar of Guarantor/co-borrower',
        type: 'number'
      }
    }
  },

  // Section H: Security Offered; A- Solar Rooftop Commercial & Industrial; B- Dealer Finance
  sectionH: {
    title: 'Section H: Security Offered',
    details: 'Solar Rooftop Commercial & Industrial',
    fields: {
      solarRooftopCommercialInstitutional: {
        label: 'Solar Rooftop Commercial & Industrial',
        type: 'radio',
        options: ['Yes', 'No']
      },
      hypothecationOfAsset: {
        label: 'Hypothecation of Asset',
        type: 'radio',
        options: ['Yes', 'No']
      },
      personalGuaranteeOfPromoterDirectors: {
        label: 'Personal Guarantee of Promoter Directors',
        type: 'radio',
        options: ['Yes', 'No']
      },
      guaranteeFromEpcContractor: {
        label: 'Guarantee from EPC Contractor',
        type: 'radio',
        options: ['Yes', 'No']
      },
      dealerFinance: {
        label: 'Dealer Finance',
        type: 'radio',
        options: ['Yes', 'No']
      },
      hypothecationOfStock: {
        label: 'Hypothecation of Stock',
        type: 'radio',
        options: ['Yes', 'No']
      },
      comfortLetterFromWaareeSolarManufacturer: {
        label: 'Comfort Letter from Waaree/Solar Manufacturer',
        type: 'radio',
        options: ['Yes', 'No']
      }
    }
  }
};

export const allowedPaths = ['/distributor/join', '/epc', '/dsa'];

export const registerTabsEnums = [
  {
    enum: 'REGISTRATION_TYPE',
    name: 'Registration Type'
  }
  // {
  //   enum: 'SOLFIN_OFFERS',
  //   name: 'Solfin Offers'
  // },
  // {
  //   enum: 'PROJECT_DETAILS',
  //   name: 'Project Details'
  // },
  // {
  //   enum: 'GST_DETAILS',
  //   name: 'GST Details'
  // },
  // {
  //   enum: 'ITR_DETAILS',
  //   name: 'ITR Details'
  // },
  // {
  //   enum: 'BANK_STATEMENT',
  //   name: 'Bank Statement'
  // }
];

export const phoneSchema = string()
  .required('Mobile number is required')
  .test('is-valid-indian-phone', 'Invalid Indian mobile number', (value) => {
    // Remove all non-digit characters (e.g., +, -, spaces)
    const cleanedValue = value.replace(/\D/g, '');

    // Check for valid Indian mobile number formats:
    // 1. 10-digit number (e.g., 9084301894)
    // 2. 11-digit number starting with 0 (e.g., 09084301894)
    // 3. 12-digit number starting with 91 (e.g., 919084301894)
    // 4. 13-digit number starting with +91 (e.g., +919084301894)
    if (
      /^\d{10}$/.test(cleanedValue) || // 10 digits
      /^0\d{10}$/.test(cleanedValue) || // 11 digits starting with 0
      /^91\d{10}$/.test(cleanedValue) || // 12 digits starting with 91
      /^\+91\d{10}$/.test(cleanedValue) // 13 digits starting with +91
    ) {
      return true;
    }

    return false;
  });

export const submitButton = {
  width: { md: 'auto', xs: '100%' }
};
