import React from 'react';
import { Helmet } from 'react-helmet';

const SEOHelmet = ({
  title,
  description,
  keywords = [],
  ogImage = 'https://solfin.co.in/images/solfin-coloured-logo.svg',
  pathname
}) => {
  const siteName =
    'Solfin - Solar Financing Solutions for Residential, C & I and Others';
  const defaultDescription =
    'Solfin delivers fast, flexible solar financing for residential and commercial projects. Enjoy quick approvals, competitive loans, and tailored payment plans.';
  const defaultKeywords = [
    'solar',
    'finance',
    'loans',
    'renewable energy',
    'solar financing',
    'solar loans',
    'rooftop solar finance',
    'renewable energy loans',
    'solar panel financing',
    'green energy finance',
    'solar power financing',
    'solar investment',
    'solar energy solutions',
    'solar finance solution'
  ];

  const allKeywords = [...defaultKeywords, ...keywords].join(', ');

  const baseUrl = process.env.REACT_APP_DOMAIN;
  const canonicalUrl = pathname ? `${baseUrl}${pathname}` : baseUrl;

  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  return (
    <Helmet>
      <title>{title ? `${title} | ${siteName}` : siteName}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={allKeywords} />

      <meta name="robots" content="index, follow" />
      <meta name="author" content="Solfin" />
      
      <link rel="canonical" href={canonicalUrl} />

      <meta property="og:site_name" content={siteName} />
      <meta property="og:title" content={title || siteName} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || siteName} />
      <meta
        name="twitter:description"
        content={description || defaultDescription}
      />
      <meta name="twitter:image" content={ogImage} />

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default SEOHelmet;