import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(
  ({ theme, secondary, variant = 'contained', color = 'primary' }) => ({
    textTransform: 'capitalize',
    boxShadow: 'none',
    borderRadius: secondary ? '20px' : '5px',
    fontFamily: secondary ? 'Montserrat Alternates' : '',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    minWidth: 'auto',
    padding: '6px 16px',
    height: '36px',

    ...(variant === 'contained' && {
      backgroundColor: color === 'primary' ? '#88F231' : '#161616',
      color: color === 'primary' ? '#161616' : '#ffffff',
      '&:hover': {
        fontFamily: secondary ? 'Montserrat Alternates' : '',
        backgroundColor: color === 'primary' ? '#161616' : '#88F231',
        boxShadow: 'none',
        color: color === 'primary' ? '#ffffff' : '#161616'
      }
    }),

    ...(variant === 'outlined' && {
      backgroundColor: 'transparent',
      borderColor: color === 'primary' ? '#88F231' : '#161616',
      color: color === 'primary' ? '#88F231' : '#161616',
      '&:hover': {
        fontFamily: secondary ? 'Montserrat Alternates' : '',
        backgroundColor:
          color === 'primary'
            ? 'rgba(136, 242, 49, 0.04)'
            : 'rgba(22, 22, 22, 0.04)',
        borderColor: color === 'primary' ? '#88F231' : '#161616'
      }
    }),

    '&.light-btn': {
      backgroundColor: '#fff',
      color: '#161616',
      fontFamily: secondary ? 'Montserrat Alternates' : '',
      border: '1px solid #161616',
      '&:hover': {
        backgroundColor: '#f8f9fa'
      }
    },

    '&.filter-btn': {
      backgroundColor: '#161616',
      fontFamily: secondary ? 'Montserrat Alternates' : '',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#88F231'
      }
    },

    '&.Mui-disabled': {
      backgroundColor: '#e9ecef !important',
      color: '#6c757d !important'
    },

    '&.small': {
      padding: '4px 12px',
      fontSize: '0.75rem',
      height: '32px'
    }
  })
);

const CustomButton = ({
  variant = 'contained',
  color = 'primary',
  children,
  startIcon,
  className = '',
  size = 'small',
  secondary = false,
  ...props
}) => {
  const buttonSize = size === 'small' ? 'small' : '';
  const combinedClassName = `${className} ${buttonSize}`.trim();

  return (
    <StyledButton
      variant={variant}
      color={color}
      startIcon={startIcon}
      className={combinedClassName}
      secondary={secondary}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
