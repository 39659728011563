import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from '../pages/home/Home';
import RouteWrapper from './RouteWrapper';
import Loader from '../../components/Loader';
import LandingPage from '../components/LandingPage';
import { UI_ROUTES } from '../../utils/routeConstants';
import NotFound from '../pages/404/404';
import HTMLSitemap from 'src/components/Sitemap/HTMLSitemap';
import { sitemapRoutes } from 'src/sitemapRoutes';

const EPCOnboardingForm = lazy(
  () => import('../pages/epc/components/EPCOnboardingForm')
);
const RefundPolicy = lazy(() => import('../pages/refundpolicy/RefundPolicy'));
const CookiePolicy = lazy(() => import('../pages/cookiePolicy/cookiePolicy'));
const ContactSales = lazy(() => import('../pages/contactSales/ContactSales'));
const ForgotPassword = lazy(() => import('../pages/login/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/login/ResetPassword'));
const ComingSoon = lazy(() => import('../pages/comingSoon/ComingSoon'));
const WantFinance = lazy(() => import('../pages/finance/WantFinance'));
const Estimator = lazy(() => import('../pages/estimator/Calculator'));
const MySavings = lazy(() => import('../pages/estimator/MySavings'));
const Knowledge = lazy(() => import('../pages/knowledge/Knowledge'));
const Register = lazy(() => import('../pages/Registration/RegistrationWrapper'));
const Header = lazy(() => import('../components/Header/Header'));
const AboutUs = lazy(() => import('../pages/aboutus/AboutUs'));
const Mission = lazy(() => import('../pages/others/Mission'));
const Login = lazy(() => import('../pages/login/Login'));
const InternalLogin = lazy(() => import('../pages/login/InternalLogin'));
const Lease = lazy(() => import('../pages/loan/Lease'));
const Dsa = lazy(() => import('../pages/dsa/Dsa'));
const TermsAndConditions = lazy(
  () => import('../pages/termsandconditions/Termsandconditions')
);
const PremiumPartners = lazy(
  () => import('../pages/premiumPartners/PremiumPartners')
);
const ResidentialLoanProducts = lazy(
  () => import('../pages/loan/LoanProducts')
);
const PartnerRegistrationComplete = lazy(
  () =>
    import('../pages/partnerregistrationcomplete/PartnerRegistrationComplete')
);
const RegistrationComplete = lazy(
  () => import('../pages/registrationcomplete/RegistrationComplete')
);

const NewCustomerRegisterComplete = lazy(
  () => import('../pages/registrationcomplete/NewCustomerRegisterComplete')
);
const OldCustomerRegisterComplete = lazy(
  () => import('../pages/registrationcomplete/OldCustomerRegisterComplete')
);
const DhanvikasPrivacyPolicy = lazy(
  () => import('../pages/privacypolicy/DhanvikasPrivacypolicy')
);
const XsPrivacyPolicy = lazy(
  () => import('../pages/privacypolicy/XSFinanacePrivacypolicy')
);
const DistributorJoinV2 = lazy(
  () => import('../pages/distributor/DistributorJoin')
);
const FairPracticeCode = lazy(
  () => import('../pages/fairPracticeCode/FairPracticeCode')
);
const ContactSalesForLease = lazy(
  () => import('../pages/contactSales/ContactSalesLease')
);
const GrievanceRedressalPolicy = lazy(
  () => import('../pages/grievanceRedressalPolicy/GrievanceRedressalPolicy')
);

const AllRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path={UI_ROUTES.dashboard}
          element={
            <RouteWrapper>
              <Home />
              <LandingPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={UI_ROUTES.signup}
          element={
            <RouteWrapper>
              <Register />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.finance}
          element={
            <RouteWrapper>
              <WantFinance />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.premiumPartners}
          element={
            <RouteWrapper>
              <PremiumPartners />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.cookiePolicy}
          element={
            <RouteWrapper>
              <CookiePolicy />
            </RouteWrapper>
          }
        />

        <Route
          path={UI_ROUTES.EPC}
          element={
            <RouteWrapper>
              <EPCOnboardingForm />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.DSA}
          element={
            <RouteWrapper>
              <Dsa />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.aboutUs}
          element={
            <RouteWrapper>
              <AboutUs />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.solarCalculator}
          element={
            <RouteWrapper>
              <Estimator />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.mySavings}
          element={
            <RouteWrapper>
              <MySavings />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.residentialLoan}
          element={
            <RouteWrapper>
              <ResidentialLoanProducts />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.knowledge}
          element={
            <RouteWrapper>
              <Knowledge />
            </RouteWrapper>
          }
        />

        <Route
          path={UI_ROUTES.solfinPrivacyPolicy}
          element={
            <RouteWrapper>
              <XsPrivacyPolicy />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.dhanvikasPrivacyPolicy}
          element={
            <RouteWrapper>
              <DhanvikasPrivacyPolicy />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.refundPolicy}
          element={
            <RouteWrapper>
              <RefundPolicy />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.dhanvikasFairPracticeCodePolicy}
          element={
            <RouteWrapper>
              <FairPracticeCode />
            </RouteWrapper>
          }
        />

        <Route
          path={UI_ROUTES.solfinGrievanceRedressalPolicy}
          element={
            <RouteWrapper>
              <GrievanceRedressalPolicy />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.termsAndConditions}
          element={
            <RouteWrapper>
              <TermsAndConditions />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.distributorJoin}
          element={
            <>
              <Header />
              <DistributorJoinV2 />
            </>
          }
        ></Route>
        <Route
          path={UI_ROUTES.registrationComplete}
          element={<RegistrationComplete />}
        />
        <Route
          path={UI_ROUTES.custRegistrationComplete}
          element={<OldCustomerRegisterComplete />}
        />
        <Route
          path={UI_ROUTES.custRegistered}
          element={<NewCustomerRegisterComplete />}
        />
        <Route
          path={UI_ROUTES.partnerRegistrationComplete}
          element={<PartnerRegistrationComplete />}
        />
        <Route
          path={UI_ROUTES.login}
          element={
            <RouteWrapper>
              <Login />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.internalLogin}
          element={
            <RouteWrapper>
              <InternalLogin />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.forgotPassword}
          element={
            <RouteWrapper>
              <ForgotPassword />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.resetPassword}
          element={
            <>
              <ResetPassword />
            </>
          }
        />
        <Route
          path={UI_ROUTES.lease}
          element={
            <RouteWrapper>
              <Lease />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.comingSoon}
          element={
            <RouteWrapper>
              <ComingSoon />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.mission}
          element={
            <RouteWrapper>
              <Mission />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.contactSales}
          element={
            <RouteWrapper>
              <ContactSales />
            </RouteWrapper>
          }
        />
        <Route
          path={UI_ROUTES.leaseContactSales}
          element={
            <RouteWrapper>
              <ContactSalesForLease />
            </RouteWrapper>
          }
        />
        <Route path={UI_ROUTES.sitemap} element={<HTMLSitemap routes={sitemapRoutes} />} />
        <Route path={'*'} element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AllRoutes;
