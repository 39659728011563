import { Navigate, useLocation, useParams } from 'react-router-dom';
import Loader from 'src/components/Loader';
import useAuthAndRedirect from 'src/Hooks/useAuth';
import { allowedPaths } from 'src/utils/constants';

const ProtectedRoute = ({ Component }) => {
  const params = useParams();
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuthAndRedirect(true);

  if (isLoading) {
    return <Loader />;
  }

  const allowedPath = allowedPaths.includes(location.pathname.split('?')[0]);

  const getRedirectPath = () => {
    if (!params['*']) return null;
    const pathSegments = params['*'].split('redirect=');
    if (pathSegments.length < 2) return null;
    const decodedPath = decodeURIComponent(pathSegments[1]);
    return decodedPath.startsWith('/') ? decodedPath : `/${decodedPath}`;
  };

  const redirectPath = getRedirectPath();

  if (!isAuthenticated && !allowedPath) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  if (!allowedPath && redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Component />;
};

export default ProtectedRoute;
