import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  setCredentials,
  clearAuth,
  setLoading
} from '../store/slices/authSlice';
import AuthService from 'src/store/services/authService';
import { auth } from 'src/FrontEnd/dataService/DataService';
import { UI_ROUTES } from 'src/utils/routeConstants';
import { allowedPaths } from 'src/utils/constants';

const useAuthAndRedirect = (
  isProtected = true,
  notFound = false,
  authUserNotAllowed = false
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isAuthenticated, isLoading, user, isDistributor } = useSelector(
    (state) => state.auth
  );
  const { permissions } = useSelector((state) => state.permissions);

  const permissionToRoutesMap = useMemo(() => ({
    LEADS_VIEW: [
      'leadsEstimator',
      'leadsApplication',
      'leadsApplicationDetail',
      'leadsEpcReferred',
      'leadsEpcReferredDetails'
    ],
    CUSTOMERS_VIEW: ['customers', 'customersDetails'],
    EPC_VIEW: [
      'epcApplicants',
      'epcApplicantsDetails',
      'epcEmpanelled',
      'epcEmpanelledDetail'
    ],
    DISTRIBUTOR_VIEW: [
      'distributorLeads',
      'distributorKyc',
      'distributorKycDetail',
      'distributorCreditRating',
      'distributorEmpanelled',
      'distributorEmpanelledApplicationDetails',
      'distributorRejected'
    ],
    DSA_VIEW: [
      'dsaApplicants',
      'dsaApplicantsDetail',
      'dsaEmpanelled',
      'dsaEmpanelledDetail'
    ],
    INVOICES_VIEW: ['invoicesPending', 'invoicesVerified'],
    PROJECTS_VIEW: ['projects', 'projectsDetail'],
    LOAN_VIEW: ['loans', 'loansDetails']
  }), []);

  const getAllowedRoutes = useCallback(
    (currentPermissions = []) => {
      const generateRoutesArray = (routes) =>
        currentPermissions
          .flatMap((permission) => permissionToRoutesMap[permission] || [])
          .map((route) => `/admin${routes[route]}`);

      const EPC_ROUTES = isDistributor
        ? Object.values(UI_ROUTES.distributor).map(
            (route) => `/distributor${route}`
          )
        : Object.values(UI_ROUTES.epc).map((route) => `/epc${route}`);

      return {
        ADMIN: Object.values(UI_ROUTES.admin).map((route) => `/admin${route}`),
        EPC: EPC_ROUTES,
        CUSTOMER: Object.values(UI_ROUTES.customer).map(
          (route) => `/customer${route}`
        ),
        DSA: Object.values(UI_ROUTES.dsa).map((route) => `/dsa${route}`),
        MANUFACTURER_PARTNER: Object.values(UI_ROUTES.manufacturer).map(
          (route) => `/manufacturer${route}`
        ),
        INTERNAL_USER: generateRoutesArray(UI_ROUTES.admin)
      };
    },
    [isDistributor, permissionToRoutesMap]
  );

  const handleRouteRedirect = useCallback(
    (currentUser) => {
      if (!currentUser) return;

      const allowedRoutes = getAllowedRoutes(permissions);
      const userTypeCode = currentUser?.otherUserData?.user_type_code;
      const userAllowedRoutes = allowedRoutes[userTypeCode] || [];
      const currentPath = location.pathname;

      const isRouteAllowed = userAllowedRoutes.some((route) =>
        route.includes(':')
          ? currentPath.startsWith(route.split(':')[0])
          : route === currentPath
      );

      if (!isRouteAllowed) {
        const defaultRoute =
          userAllowedRoutes.find((route) => route.includes('dashboard')) ||
          userAllowedRoutes[0];
        
        if (defaultRoute) {
          navigate(defaultRoute, { replace: true });
        }
      }
    },
    [navigate, location.pathname, permissions, getAllowedRoutes]
  );

  const redirectToLogin = useCallback(() => {
    dispatch(clearAuth());
    const currentPath = location.pathname;
    
    if (currentPath === '/register') {
      navigate('/register', { state: { from: currentPath } });
    } else {
      navigate('/login', { state: { from: currentPath } });
    }
  }, [dispatch, navigate, location.pathname]);

  useEffect(() => {
    if (isAuthenticated && user) {
      handleRouteRedirect(user);
    }
  }, [isAuthenticated, user, handleRouteRedirect]);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        dispatch(setLoading(true));
        const storedAuth = AuthService.getAuth();
        
        if (!storedAuth?.token) {
          redirectToLogin();
          return;
        }

        const authResponse = await auth();
        if (authResponse === 400) {
          redirectToLogin();
          return;
        }

        dispatch(setCredentials(storedAuth));
      } catch (error) {
        console.error('Error during auth initialization:', error);
        redirectToLogin();
      } finally {
        dispatch(setLoading(false));
      }
    };

    const isAllowedPath = allowedPaths.includes(location.pathname.split('?')[0]);

    if (isAllowedPath) {
      dispatch(setLoading(false));
    } else if (notFound) {
      if (!isAuthenticated) {
        initializeAuth();
      }
    } else if (authUserNotAllowed) {
      initializeAuth();
    } else if (!isProtected || isAuthenticated) {
      dispatch(setLoading(false));
    } else {
      initializeAuth();
    }
    
  }, [
    dispatch, 
    isProtected, 
    notFound, 
    authUserNotAllowed, 
    isAuthenticated, 
    redirectToLogin, 
    location.pathname
  ]);

  return { isAuthenticated, isLoading };
};

export default useAuthAndRedirect;